import { useParams } from 'react-router-dom'
import dataFetcher from './dataFetcher'
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation' // import loading animation component
import './RedirectMessage.css'
import logo from '../../assets/logo.svg'; // import logo from logo.svg file

const URLloader = () => {
    const { shorted } = useParams() // get shorted url from url
    const data = dataFetcher(shorted) // get data from dataFetcher function
    console.log(data) // log data to console

    // Redirect to url after delay
    setTimeout(() => {
        window.location.href = data.url
    }, data.delay) 

    return (
        <div class="hth-card">
            <div class="hth-card-top">
                <img src={logo} alt="Hack the Hill Logo" /> 
            </div>
            <div class="hth-card-middle">
                <LoadingAnimation />
            </div>
            <div class="hth-card-bottom">
                <p>{data.message} If the page fails to load click <a href={data.url}>here</a>.</p>
            </div>
        </div>
    )
}

export default URLloader;
