// import config.json
import config from '../../config.json'



export default function dataFetcher(shortedURL) {
    const redirectConfig = config.redirectConfig; 
    const defaultConfig = config.defaultConfig; 

    if (shortedURL in redirectConfig) {
        const currentData = redirectConfig[shortedURL];
        const sendData = {};

        // Check to see if there is a url to redirect to
        if (currentData.url) {
            sendData.url = currentData.url;
        } else{
            sendData.url = defaultConfig.url;
        }

        // Check to see if there is a custom message to display
        if (currentData.message) {
            sendData.message = currentData.message;
        } else{
            sendData.message = defaultConfig.message;
        }

        // Check to see if there is a custom delay to display
        if (currentData.delay) {
            sendData.delay = currentData.delay;
        }
        else{
            sendData.delay = defaultConfig.delay;
        }

        return sendData;
    }
    else {
        return defaultConfig;
    }
}

