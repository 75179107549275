import RedirectMessage from '../RedirectMessage/RedirectMessage'; // import redirect function from redirect.js
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import react router dom 



function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<RedirectMessage />} /> 
        </Routes>
        <Routes>
          <Route path="/:shorted" element={<RedirectMessage />} /> 
        </Routes>
      </Router>
    </div>
  );
}

export default App;
