
import './LoadingAnimation.css';

const LoadingAnimation = () => {
    return (
        <div class="loadingBlocks">
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default LoadingAnimation;
